import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section1 from "../sections/Section1/Section1"
import Section2 from "../sections/Section2/Section2"
import Section3 from "../sections/Section3/Section3"

import "../assets/css/index.css"

export default function Home(props) {
  const runCallback = cb => {
    return cb()
  }

  return (
    <Layout>
      <main className="page-default">
        <Section1 />
        <Section2 />
        <Section3 />
      </main>
      <ul id="bgAnim">
        {runCallback(() => {
          const row = []
          for (var i = 0; i < 10; i++) {
            row.push(<li key={i}></li>)
          }
          return row
        })}
      </ul>
    </Layout>
  )
}

export const Head = props => (
  <Seo title="Home | Angga Hermawan" pathname={props.location?.pathname} />
)
