import React, { Component } from "react"
import { BsArrowRight } from "react-icons/bs"
// import ReCAPTCHA from "react-google-recaptcha"

import { BackgroundBeams } from "../../components/ui/BackgroundBeams"
import "./Section3.scss"
import { LabelInputContainer } from "../../components/ui/LabelInputContainer"
import { Label } from "../../components/ui/Label"
import { Input } from "../../components/ui/Input"

export default class Section3 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: false,
      captcha: null,
    }
    this.onCaptchaChange = this.onCaptchaChange.bind()

    this.recaptchaRef = React.createRef(null)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.captcha !== prevState.captcha) {
      this.recaptchaRef.current.execute()
    }
  }

  handleChange = e => {
    if (e) {
      this.setState({
        files: e,
      })
    }
  }

  onCaptchaChange = val => {
    this.setState({
      captcha: val,
    })
  }

  render() {
    // let files
    // if (this.state.files){
    //     files = <label className='filename'>{this.state.files.name}</label>
    // }
    return (
      <section id="s3">
        <div className="content">
          <BackgroundBeams />
          <article>
            <h5>- Contact</h5>
            <h4>
              Got a project? <br /> Let's talk
            </h4>
            <p>
              There is no one who loves their first website, who seeks after it
              and wants to have it, simply call me...
            </p>
            {/* <p>There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain...</p> */}

            <a className="mailto" href="mailto:anggah.net@gmail.com">
              anggah.net@gmail.com <BsArrowRight />
            </a>
          </article>
          <div className="content-form">
            <form
              action="https://formspree.io/f/mvodrzae"
              method="POST"
              encType="multipart/form-data"
              className="relative flex flex-col gap-4"
            >
              <LabelInputContainer>
                <Label htmlFor="name">What's your name</Label>
                <Input id="name" name="name" type="text" />
              </LabelInputContainer>
              <LabelInputContainer>
                <Label htmlFor="email">Your fancy email</Label>
                <Input id="email" name="email" type="email" />
              </LabelInputContainer>
              <LabelInputContainer>
                <Label htmlFor="message">Your message</Label>
                <Input id="message" name="message" type="textarea" rows="5" />
              </LabelInputContainer>

              {/* <div className='form-default'>
                                <label htmlFor='project'>Tell me your detail project <BsPaperclip /></label>
                                <input id='project' name='file' type='file' hidden onChange={(e) => this.handleChange(e.target.files[0])} />

                                {files}
                            </div> */}
              {/* <ReCAPTCHA
                ref={this.recaptchaRef}
                sitekey={process.env.SITE_CAPTCHA_KEY}
                onChange={e => this.onCaptchaChange(e)}
              /> */}
              <button type="submit">Send</button>
            </form>
          </div>
        </div>
      </section>
    )
  }
}
