import React, { Component } from "react"
import { Link } from "gatsby"
import { FaDesktop, FaDatabase, FaCode, FaMobileAlt } from "react-icons/fa"
import { BsArrowRight } from "react-icons/bs"

// import { Meteors } from "../../components/ui/Meteors"
import "./Section2.scss"

export default class Section2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [
        {
          title: "Desktop App",
          value: "10 projects",
          icon: <FaDesktop />,
        },
        {
          title: "Full Stack Developer",
          value: "2 projects",
          icon: <FaDatabase />,
        },
        {
          title: "Frontend Developer",
          value: "10 projects",
          icon: <FaCode />,
        },
        {
          title: "Mobile Frontend Developer",
          value: "1 project",
          icon: <FaMobileAlt />,
        },
      ],
    }
  }

  render() {
    const { projects } = this.state

    let allProject = projects.map(pr => {
      return (
        <div key={pr.title} className="box-project">
          {pr.icon}
          <div>
            <p>{pr.title}</p>
            <span>{pr.value}</span>
          </div>
          {/* <Meteors /> */}
        </div>
      )
    })

    return (
      <section id="s2">
        <div className="contents">
          <div className="content">
            <article>
              <h5>- Projects</h5>
              <p>
                As a developer, my first love will always be coding with a cup
                of coffee. I was able to leverage my creative skills with an
                incredible web development toolkit, and now I spend most days
                learn new technology and building out various website or app.
              </p>
              <div className="content-exp">
                <div>
                  <p>&lt;3</p>
                  <span>years as Software Developer</span>
                </div>
              </div>
              <div className="content-exp">
                <div>
                  <p>&gt;4</p>
                  <span>years as Web Developer</span>
                </div>
              </div>
              <Link to="/projects" className="my-8">
                Latest project <BsArrowRight />
              </Link>
            </article>
            <div className="list-projects">{allProject}</div>
          </div>
        </div>
      </section>
    )
  }
}
